(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/helpers/account-observer.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/helpers/account-observer.js');
'use strict';

const _excluded = ["emitCurrentValue"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  log
} = svs.core;
const {
  url
} = svs.utils;
const {
  accountSelector
} = svs.components.marketplace;
const logger = new log.Logger('account-selector');
let value = {
  accounts: []
};
let handlers = [];
const unsubscribe = func => {
  let i = handlers.length;
  while (i--) {
    if (handlers[i].name === func.name && handlers[i].toString() === func.toString()) {
      handlers.splice(i, 1);
    }
  }
};
const subscribe = function (func) {
  let _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let {
      emitCurrentValue
    } = _ref,
    unknownOptions = _objectWithoutProperties(_ref, _excluded);
  if (Object.keys(unknownOptions).length > 0) {
    throw Error("Unknown options: ".concat(Object.keys(unknownOptions).join(', ')));
  }
  unsubscribe(func);
  handlers.push(func);
  if (emitCurrentValue) {
    func(value);
  }
};
const notify = function () {
  let newValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  value = _objectSpread(_objectSpread(_objectSpread({}, value), newValue), {}, {
    currentAccount: accountSelector.helpers.getCurrentAccount(newValue.accounts)
  });
  handlers.forEach(fn => {
    try {
      fn(value);
    } catch (error) {
      logger.error("Failed to notify callback: ", error.toString());
    }
  });
  url.refreshQueryString();
};
const getValue = () => value;
const clearSubscriptions = () => {
  handlers = [];
};
const observer = {
  subscribe,
  notify,
  unsubscribe,
  handlers,
  getValue,
  clearSubscriptions
};
setGlobal('svs.components.marketplace.accountSelector.helpers.observer', observer);

 })(window);